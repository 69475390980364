import {
  Address, PriceGroup, Sort, Price,
} from '@printdeal/product-selector';
import { configurationProvider } from '@printdeal/configuration';
import { getKeyValueObjectOfMatchedOutcomes } from '../../helpers/MerchantRulesHelper';
import Api from '../Api';
import { AttributeFormat, MatchedOutcome, Rules } from '../../types/product/ProductRules';
import { ISelection } from '../../types/selector/Selector';
import { McpProduct } from '../../types/product/product';

export interface GetProductPricesParams {
  productKey: string,
  selection: ISelection,
  address: Address,
  quantity: number[],
  mcpSku: string,
  customerGroupId?: string,
  sort: Sort[],
  group?: PriceGroup[],
  customHeaders?: Record<string, string>,
  productVersion?: number,
}

class ProductService extends Api {
  /**
   * Evaluate merchant rules with the givin facts
   * @param {string} productSku - Sku of the product to match the rules against
   * @param {array<object>} facts - Example,
   * if areAttributeLabels = false: [{ "key": "Printing_Colors___LOV", "value": "4/4 Full Color"}]
   * if areAttributeLabels = true: UserSelection
   * @param {bool} areAttributeLabels - To switch between facts format
   * @returns {Promise<*>}
   */
  async evaluateMerchantRules(
    productSku: string,
    facts: AttributeFormat[],
    areAttributeLabels: boolean = true,
  ): Promise<MatchedOutcome[] | null> {
    try {
      const params = new URLSearchParams();
      params.append('storeKey', configurationProvider.getValue('STORE_KEY'));
      params.append('facts', JSON.stringify(facts));
      params.append('areAttributeLabels', String(areAttributeLabels));
      const { data: { matchedOutcomes } } = await this.httpClient.get(
        `/product/evaluateMerchantRules/${productSku}?${params}`,
      );
      // eslint-disable-next-line @typescript-eslint/return-await
      return Promise.resolve(matchedOutcomes);
    } catch (e) {
      return Promise.resolve(null);
    }
  }

  /**
   * Get delivery tool settings
   * @param {string} productSku - Sku of the product to match the rules against
   * @param {array<object>} facts - Example,
   * if areAttributeLabels = false: [{ "key": "Printing_Colors___LOV", "value": "4/4 Full Color"}]
   * @param {boolean} areAttributeLabels
   */
  async getMerchantRules(
    productSku: string,
    facts: AttributeFormat[],
    areAttributeLabels: boolean = true,
  ): Promise<Rules> {
    const defaults = {
      // "Checkmatemode: checkmate" and "checkmate: on" will be renamed when MRC is replaced by the business rules app
      checkmate: 'off',
      editor: 'off',
      altuploadbeforeorder: 'off',
    };

    try {
      const ruleOutcomes = await this.evaluateMerchantRules(productSku, facts, areAttributeLabels);

      return {
        deliveryTool: {
          ...defaults,
          ...getKeyValueObjectOfMatchedOutcomes(
            ruleOutcomes,
            [
              'editor',
              'editorMode',
              // "Checkmatemode: checkmate" and "checkmate: on" will be renamed
              // when MRC is replaced by the business rules app
              'checkmate',
              'checkmateMode',
              'templateURL',
              'multipleDesigns',
              'ncolorcount',
              'assetTenant',
              'altUploadBeforeOrder',
              'EditorOverrides',
              'serviceKeys',
              '3d',
            ],
          ),
        },
        ...getKeyValueObjectOfMatchedOutcomes(ruleOutcomes, ['cutOffTime']),
      };
    } catch (e) {
      return { deliveryTool: defaults };
    }
  }

  /**
   * This can be used as a fetcher
   */
  get = async <T>(url: string) => {
    const { data } = await this.httpClient.get<T>(url);
    return Promise.resolve(data);
  };

  validateSelection = async ({
    selection,
    comboSelection,
    productDetailPageId,
    customerGroupId,
  }: {
    selection: string,
    comboSelection: string,
    productDetailPageId: string,
    customerGroupId?: string
  }): Promise<{ valid: boolean, price: Price, originalPrice: Price }> => {
    const params = new URLSearchParams();
    params.append('storeKey', configurationProvider.getValue('STORE_KEY'));
    params.append('selection', selection);

    if (comboSelection) {
      params.append('comboSelection', comboSelection);
    }

    if (customerGroupId) {
      params.append('customerGroupId', customerGroupId);
    }

    const { data } = await this.httpClient.get(`/product/validate/${productDetailPageId}`, {
      params,
    });
    return Promise.resolve(data);
  };

  getProductDiscount = async ({
    productKey,
    quantity,
    centAmount,
    customerGroupId,
  }: {
    productKey: string,
    quantity?: number,
    centAmount?: number,
    customerGroupId?: string,
  }) => {
    const params = new URLSearchParams();
    params.append('storeKey', process.env.GATSBY_STORE || '');

    if (quantity) {
      params.append('quantity', String(quantity));
    }

    if (centAmount) {
      params.append('centAmount', String(centAmount));
    }

    if (customerGroupId) {
      params.append('customerGroupId', customerGroupId);
    }

    const { data } = await this.httpClient.get(`/product/${productKey}/discounts`, {
      params,
    });
    return Promise.resolve(data);
  };

  convertProduct = async (
    variableAttributes: ISelection, productKey?: string, customHeaders?: Record<string, string>,
  ): Promise<McpProduct | undefined> => {
    if (!productKey) {
      return undefined;
    }

    const params = new URLSearchParams();
    params.append('variableAttributes', JSON.stringify(variableAttributes));
    const { data: mcpProduct } = await this.httpClient.get<McpProduct>(`/product/convert/${productKey}`, {
      params,
      headers: customHeaders || {},
    });
    return Promise.resolve(mcpProduct);
  };
}

export default ProductService;
